<script lang="ts" setup>
const { t } = useT();

const { data: appInit } = useAppInitData();
const {
	gamesWithWeekGame,
	seoData,
	refresh: refreshHomeData,
	questData: questDataHome,
	jackpotGames,
	holdNLinksGames,
	yourPick,
	featuredGamesArrayable,
	bonusBuyGames,
	missedItGamesArrayable
} = useHomePage();

useHead({
	title: seoData.value?.seoTitle || "TaoFortune",
	meta: [
		{
			name: "description",
			content: seoData.value?.seoDescription
		},
		{
			name: "keywords",
			content: seoData.value?.seoKeywords || ""
		}
	]
});

const { isSweepStakes } = useSwitchMode();
const isGuest = computed(() => !!appInit.value?.isGuest);
const isSeasonQuest = computed(() => questDataHome.value?.quest?.questInfo?.type === "season");

watch(isGuest, () => {
	setTimeout(() => {
		refreshHomeData();
	}, 2000);
});
</script>

<template>
	<NuxtLayout>
		<OBanners />

		<OOpportunity v-if="isGuest" class="box-opportunity" />

		<template v-if="!isGuest">
			<LazyOHomeLatestWinners />
			<OSwitcher v-if="!isSweepStakes" />
		</template>

		<LazyOGamesList
			v-if="gamesWithWeekGame?.length"
			type="favorite"
			withGameOfWeek
			:title="t('Tao favorites')"
			:description="t('Not a single second would be boring')"
		/>

		<OLazyComponent v-if="!isGuest" id="promotions">
			<LazyOHomePromotions />
		</OLazyComponent>

		<OLazyComponent id="yourPickGames">
			<LazyOHomeGamesSlider title="your picks" :games="yourPick" route="/issues/favorites/" :is-guest="isGuest" />
		</OLazyComponent>

		<OLazyComponent id="jackpot">
			<OJackpot />
		</OLazyComponent>

		<OLazyComponent v-if="!isGuest" id="jackpotGames">
			<LazyOGamesList v-if="jackpotGames?.length" type="jackpot" :title="t('Jackpot games')" />
		</OLazyComponent>

		<OLazyComponent id="holdNLinksGames">
			<LazyOHomeGamesSlider
				title="Hold-n-Link games"
				:games="holdNLinksGames"
				route="/issues/hold-n-link/"
				:is-guest="isGuest"
			/>
		</OLazyComponent>

		<OLazyComponent v-if="!isGuest && appInit?.moneyBox?.isActive" id="piggyBankBanner">
			<LazyOPiggybankBanner />
		</OLazyComponent>

		<OLazyComponent v-if="!isGuest && questDataHome?.quest && !isSeasonQuest" id="questBanner">
			<OQuestsPageBanner isHomePage class="quest-banner" />
		</OLazyComponent>

		<template v-if="isGuest">
			<OLazyComponent id="latestWinners">
				<OHomeLatestWinners />
			</OLazyComponent>
			<OLazyComponent id="benefits">
				<OBenefits />
			</OLazyComponent>
			<OLazyComponent id="comments">
				<OComments />
			</OLazyComponent>
			<OLazyComponent id="registrationForm">
				<OOpenRegistrationForm />
			</OLazyComponent>
		</template>

		<template v-if="!isGuest">
			<OLazyComponent v-if="featuredGamesArrayable && featuredGamesArrayable.length" id="featuredGames">
				<div>
					<LazyOHomeGamesSlider
						title="Featured Games"
						:games="featuredGamesArrayable"
						route="/issues/featured-games/"
						:is-guest="isGuest"
						:isSection="true"
					/>
				</div>
			</OLazyComponent>

			<OLazyComponent v-if="bonusBuyGames && bonusBuyGames.length" id="bonusBuyGames">
				<div>
					<LazyOHomeGamesSlider
						title="Bonus Buy"
						:games="bonusBuyGames"
						route="/issues/bonus-buy/"
						:is-guest="isGuest"
					/>
				</div>
			</OLazyComponent>

			<OLazyComponent v-if="missedItGamesArrayable && missedItGamesArrayable.length" id="missedItGames">
				<div>
					<LazyOHomeGamesSlider
						title="In Case you missed it"
						:games="missedItGamesArrayable"
						route="/issues/missed-it/"
						:is-guest="isGuest"
						:isSection="true"
					/>
				</div>
			</OLazyComponent>
		</template>
	</NuxtLayout>
</template>

<style lang="scss" scoped>
.swiper-slide {
	width: 207px;

	@include media-breakpoint-down(md) {
		width: 164px;
		padding-right: gutter(1.25);
	}
}

.games-list__item {
	width: 193px;
	min-height: 134px;
}

.quest-banner {
	margin-top: gutter(5);
}

.box-recent-foot {
	padding-top: gutter(6.25);
	@include media-breakpoint-down(md) {
		padding-top: gutter(5);
	}
}

.box-opportunity {
	margin-top: 0px;
}
</style>
